import { PageProps } from "@/types"
import { Shield, GitBranch, RefreshCw, AlertTriangle, Check } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { useState } from "react"
import axios from "axios"
import { toast } from "sonner"
import { format } from "timeago.js"
import AuthLayout from "@/layouts/AuthLayout"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"

// Configure axios to include CSRF token
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

interface ExposedRepository {
  id: number
  name: string
  url: string
  discovered_at: string
}

interface RepoTracker {
  id: number
  name: string
  identifier: string
  repository_url: string | null
  is_active: boolean
  last_scanned_at: string | null
  exposed_repositories: ExposedRepository[]
}

interface Props extends PageProps {
  trackers: RepoTracker[]
  routes: {
    generate: string
    scan: string
  }
}

interface NewTrackerForm {
  name: string
  repository_url: string
}

// Add this helper function
const formatLastScanned = (timestamp: string | null) => {
  if (!timestamp) return "Never"
  return format(new Date(timestamp))  // This will return strings like "3 hours ago", "2 days ago", etc.
}

export default function Dashboard({ auth, trackers = [], routes }: Props) {
  const [isGenerating, setIsGenerating] = useState(false)
  const [isScanning, setIsScanning] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [formData, setFormData] = useState<NewTrackerForm>({
    name: '',
    repository_url: ''
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const generateNewIdentifier = async () => {
    setIsGenerating(true)
    try {
      const response = await axios.post(routes.generate, formData)
      console.log('Success:', response.data)
      toast("New identifier generation started. Please wait a moment and refresh.")
      setIsDialogOpen(false)
      setTimeout(() => window.location.reload(), 2000)
    } catch (error: any) {
      console.error('Error:', error.response?.data || error)
      toast(error.response?.data?.error || "Failed to generate identifier. Please try again.")
    } finally {
      setIsGenerating(false)
    }
  }

  const runScan = async (trackerId: number) => {
    setIsScanning(true)
    try {
      const scanUrl = routes.scan.replace(':id', trackerId.toString())
      await axios.post(scanUrl)
      toast("Scan started. Results will be updated shortly.")
      setTimeout(() => window.location.reload(), 2000)
    } catch (error) {
      toast("Failed to start scan. Please try again.")
    } finally {
      setIsScanning(false)
    }
  }

  if (!auth?.user) {
    return null
  }

  return (
      <div className="container mx-auto px-4 py-8">
        <div className="mx-auto max-w-7xl">
          {/* Generate New Identifier Button with Dialog */}
          <div className="mb-6">
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button>
                  <GitBranch className="mr-2 h-4 w-4" />
                  Generate New Identifier
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>New Repository Tracker</DialogTitle>
                  <DialogDescription>
                    Create a new identifier to track potential exposures.
                  </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid gap-2">
                    <Label htmlFor="name">Tracker Name</Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder="My Project"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="repository_url">Your Repository URL (optional)</Label>
                    <Input
                      id="repository_url"
                      name="repository_url"
                      placeholder="https://github.com/owner/repository"
                      value={formData.repository_url}
                      onChange={handleInputChange}
                    />
                    <p className="text-sm text-muted-foreground">
                      Link your legitimate repository to exclude it from exposure alerts
                    </p>
                  </div>
                  <Button 
                    onClick={generateNewIdentifier} 
                    disabled={isGenerating || !formData.name}
                  >
                    {isGenerating ? "Generating..." : "Generate Identifier"}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>

          {trackers.length === 0 ? (
            <Card>
              <CardHeader>
                <CardTitle>Get Started</CardTitle>
                <CardDescription>
                  Generate your first repository identifier to begin monitoring
                </CardDescription>
              </CardHeader>
              <CardContent className="flex justify-center">
                <Button onClick={generateNewIdentifier} disabled={isGenerating}>
                  <Shield className="mr-2 h-4 w-4" />
                  Generate First Identifier
                </Button>
              </CardContent>
            </Card>
          ) : (
            <div className="grid gap-6">
              {trackers.map((tracker) => (
                <Card key={tracker.id}>
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <div>
                        <CardTitle>{tracker.name || "Unnamed Tracker"}</CardTitle>
                        <CardDescription>
                          Last scanned: {formatLastScanned(tracker.last_scanned_at)}
                        </CardDescription>
                      </div>
                      <Button
                        variant="outline"
                        onClick={() => runScan(tracker.id)}
                        disabled={isScanning}
                      >
                        <RefreshCw
                          className={`mr-2 h-4 w-4 ${
                            isScanning ? "animate-spin" : ""
                          }`}
                        />
                        Scan Now
                      </Button>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-4">
                      <div className="rounded-lg bg-muted p-4">
                        <pre className="text-sm">{tracker.identifier}</pre>
                      </div>

                      {tracker.repository_url && (
                        <div className="flex items-center space-x-2 text-muted-foreground">
                          <Check className="h-5 w-5" />
                          <div>
                            <p className="font-medium">Linked Repository</p>
                            <a
                              href={tracker.repository_url}
                              target="_blank"
                              className="text-sm underline"
                            >
                              {tracker.repository_url}
                            </a>
                          </div>
                        </div>
                      )}

                      {tracker.last_scanned_at ? (
                        (tracker.exposed_repositories || []).length > 0 ? (
                          <div className="space-y-2">
                            <p className="font-medium text-destructive flex items-center">
                              <AlertTriangle className="h-5 w-5 mr-2" />
                              Exposures Detected!
                            </p>
                            <div className="space-y-1">
                              {(tracker.exposed_repositories || []).map((repo) => (
                                <div key={repo.id} className="text-sm">
                                  <a
                                    href={repo.url}
                                    target="_blank"
                                    className="text-destructive underline"
                                  >
                                    {repo.name}
                                  </a>
                                  <span className="text-muted-foreground ml-2">
                                    (found {format(new Date(repo.discovered_at))})
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center space-x-2 text-muted-foreground">
                            <Check className="h-5 w-5 text-green-600" />
                            <p className="font-medium text-green-600">No exposures detected</p>
                          </div>
                        )
                      ) : (
                        <div className="flex items-center space-x-2 text-muted-foreground">
                          <AlertTriangle className="h-5 w-5" />
                          <div>
                            <p className="font-medium">Ready to monitor</p>
                            <p className="text-sm">
                              Insert this identifier in your repository to begin monitoring.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </div>
      </div>
  )
} 

Dashboard.layout = (page: React.ReactNode) => <AuthLayout title="Dashboard">{page}</AuthLayout>