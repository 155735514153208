import { Avatar, AvatarFallback } from "@/components/ui/avatar"

import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu"
import { Shield } from "lucide-react"
import { Link, router, usePage } from "@inertiajs/react"

const Navbar = () => {
  const { auth } = usePage<{ auth: {
    user: {
      name: string
    } | null
  } }>().props

  const handleLogout = () => {
    router.delete('/logout', {
      preserveState: false,
      preserveScroll: false,
    })
  }

  return (
    <nav className="flex justify-between items-center border-b px-4 sm:px-8 py-2 bg-white sticky top-0 z-10">
      <Link href={"/dashboard"} className="flex items-center text-lg font-medium">
        <div className="flex items-center text-lg font-medium">
          <Shield className="w-6 h-6 mr-2" />
          RepoGuard
        </div>
      </Link>

      <DropdownMenu>
        <DropdownMenuTrigger className="outline-none">
          <Avatar className="h-8 w-8">
            <AvatarFallback>{auth?.user?.name?.charAt(0).toUpperCase()}</AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={handleLogout} className="cursor-pointer">
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
        </DropdownMenu>
    </nav>
  )
}

export default Navbar
