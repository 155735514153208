import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Shield, GitBranch, AlertTriangle } from "lucide-react";
import { Head, Link } from '@inertiajs/react';
import { PageProps } from "@/types";

// Add layout import
import AuthLayout from "@/layouts/AuthLayout";

export default function Welcome({ auth }: PageProps) {
    // If user is authenticated, they will be redirected by the controller
    return (
        <>
            <Head title="Welcome to RepoGuard" />
            <div className="min-h-screen bg-background">
                {/* Header */}
                <header className="border-b">
                    <div className="container mx-auto px-4 py-4 flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <Shield className="h-6 w-6 text-primary" />
                            <span className="text-xl font-bold">RepoGuard</span>
                        </div>
                        <nav>
                            <div className="space-x-2">
                                <Link href={"/login"}>
                                    <Button variant="ghost">Login</Button>
                                </Link>
                                <Link href={"/register"}>
                                    <Button>Register</Button>
                                </Link>
                            </div>
                        </nav>
                    </div>
                </header>

                <div className="px-4 sm:px-16">
                    {/* Hero Section */}
                    <section className="container mx-auto px-4 py-16 text-center">
                        <h1 className="text-4xl font-bold tracking-tight sm:text-6xl mb-6">
                            Protect Your Code From Exposure
                        </h1>
                        <p className="text-xl text-muted-foreground mb-8 max-w-2xl mx-auto">
                            Monitor your Git repositories for unauthorized exposure with our advanced detection system.
                        </p>
                        <div className="flex justify-center gap-4">
                            <Link href={"/register"}>
                                <Button size="lg">
                                    Get Started
                                    <GitBranch className="ml-2 h-4 w-4" />
                                </Button>
                            </Link>
                            <Button size="lg" variant="outline">
                                Learn More
                            </Button>
                        </div>
                    </section>

                    {/* Features */}
                    <section className="container mx-auto px-4 py-16">
                        <div className="grid md:grid-cols-3 gap-6">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Stealth Monitoring</CardTitle>
                                    <CardDescription>
                                        Undetectable repository markers
                                    </CardDescription>
                                </CardHeader>
                                <CardContent>
                                    Advanced string placement algorithm that seamlessly integrates with your codebase.
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader>
                                    <CardTitle>Real-time Alerts</CardTitle>
                                    <CardDescription>
                                        Instant notification system
                                    </CardDescription>
                                </CardHeader>
                                <CardContent>
                                    Get notified immediately when your protected code is detected in unauthorized locations.
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader>
                                    <CardTitle>Wide Coverage</CardTitle>
                                    <CardDescription>
                                        Comprehensive scanning
                                    </CardDescription>
                                </CardHeader>
                                <CardContent>
                                    Monitor GitHub, GitLab, Bitbucket, and popular code sharing platforms for potential leaks.
                                </CardContent>
                            </Card>
                        </div>
                    </section>

                    {/* Security Alert Demo */}
                    <section className="container mx-auto px-4 py-16">
                        <div className="bg-muted rounded-lg p-8">
                            <div className="flex items-center justify-center space-x-4 text-center">
                                <AlertTriangle className="h-8 w-8 text-destructive" />
                                <div>
                                    <h3 className="text-2xl font-bold mb-2">See It In Action</h3>
                                    <p className="text-muted-foreground">
                                        Watch how RepoGuard detects and alerts you about potential code exposure in real-time.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
