import { PropsWithChildren } from "react"
import { Toaster } from "sonner"
import { Head } from "@inertiajs/react"
import Navbar from "./Navbar"

type AuthLayoutProps = PropsWithChildren<{ title: string }>

export default function AuthLayout({ children, title }: AuthLayoutProps) {
  
  return (
    <>
      <Head title={title} />
      <Navbar />    
      <div className="min-h-screen flex flex-col bg-background">
        <main className="flex-1">
          {children}
          <Toaster />
        </main>
      </div>
    </>
  )
} 
